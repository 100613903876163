
#header {
    background: #000;
    color: #fff;
    padding: 10px;
}

#header .menu-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    z-index: 99;
    padding: 30px 0;
}

#header .menu-container .menu-inner-container {
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
}

#header .menu-container .menu-inner-container .menu-item-container {
    display: flex;
    justify-content: right;
}

#header .menu-container .menu-inner-container img {
    height: 40px;
    visibility: hidden;
    cursor: pointer;
}

#header .menu-container .menu-inner-container img.active {
    visibility: visible;
    margin-left: 20px;
}

#header .menu-container ul {
    margin: 0; padding: 0;
    display: flex;
    list-style: none;
    justify-content: right;
}

#header .menu-container ul li {
    margin: 0 20px;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}

#header .menu-container ul li:after {
    content: "";
    position: absolute;
    background: url('/public/icons/menu-hover.png') no-repeat center;
    left: 0;
    bottom: -20px;
    height: 100%;
    width: 100%;
    display: none;
}

#header .menu-container ul li:hover::after {
    display: block;
}

.lang-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.lang-container .lang-separator {
    width: 1px; height: 15px;
    background: #fff;
}

.lang-container button {
    padding: 0; margin: 0;
    color: #fff;
}

.lang-container button.active {
    color: #fa1d4a;
}

.header-container {
    display: flex;
    flex-direction: row;
    #height: 470px;
    height: calc(100vh - 100px);
    margin-top: 80px;
    align-items: center;
}

.header-container .logo-container img {
    width: 400px;
}

#header .slider-container {
    width: 100%;
    height: 70%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

#header .slider-container img {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    #filter: grayscale(100%);
}


.menu-small {
    justify-content: space-between;
    display: none;
    width: 100%;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    z-index: 99;
}

.menu-small .logo-container img {
    height: 100px;
}

.hms-icon-container {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid #fff;
    display: none;
    padding-top: 1px;
    margin-top: 0;
    margin-right: 20px;
}

.hms-icon-line {
    background: #fff;
    width: calc(100% - 14px);
    height: 3px;
    margin: 7px 7px;
    border-radius: 2px;
}


.menu-small {
}

.mobile-menu {
    #position: relative;
    margin-right: 30px;
}

.mobile-menu img {
    width: 30px;
}

.mobile-menu-item-container {
    position: absolute;
    top: 100px;
    right: 0;
    background: #000;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all .3s;
}

.mobile-menu-item-container.open {
    height: auto;
    transition: all .3s;
}

.mobile-menu-item-inner-container {
    padding: 30px;
    border-top: 1px solid #222;
}

.mobile-menu-item-container .lang-container {
    justify-content: right;
    margin-top: 10px;
}

.mobile-menu-item-container ul {
    list-style: none;
    margin: 0;
}

.mobile-menu-item-container ul li {
    line-height: 40px;
    text-align: right;
    text-transform: uppercase;
}

@media only screen and (max-width: 1000px) {
    .header-container .logo-container img {
        width: 300px;
    }
}


@media only screen and (max-width: 750px) {
    #header {
        padding: 0;
    }
    #header .menu-container {
        display: none;
    }
    .menu-small {
        display: flex;
    }
    .header-container .logo-container {
        display: none;
    }
    .hms-icon-container {
        display: block;
    }

    #header .header-container {
        height: 200px;
    }

    #header .slider-container img {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        object-position: center;
        object-fit: cover;
    }

    #header .slider-container {
        height: 100%;
    }

}
