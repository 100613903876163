
#contact {
    padding: 20px;
    background: #000;
}

#contact h2 {
    margin: 30px auto; padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 2rem;
    color: #fff;
}

.contact-container {
    display: flex;
}

.contact-container form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact-container form input,
.contact-container form textarea {

    width: calc(100% - 40px);
    margin: 10px 20px;
    height: 40px;
    padding: 0 10px;
}

.contact-container form textarea {
    resize: none;
    height: 100px;
    padding: 10px;
}

.contact-container form input:focus-visible,
.contact-container form textarea:focus-visible {
    outline: none;
}

.contact-container form button {
    margin-right: 20px;
    height: 40px;
    width: 200px;
    margin-top: 10px;
    background: #fa1d4a;
    border: 1px solid #fa1d4a;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.contact-container .logo-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-container .logo-container img {
    height: 200px;
}

.contact-container .social-container {
    display: flex;
    justify-content: space-around;
    margin-right: 20px;
}

.contact-container .social-container img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

@media only screen and (max-width: 800px) {

    .contact-container {
        flex-direction: column;
        justify-content: center;
    }

    .contact-container .logo-container {
        width: 100%;
    }

    .contact-container form {
        width: 100%;
    }

    .contact-container form input,
    .contact-container form textarea {
        margin: 10px auto;
    }
}

@media only screen and (max-width: 800px) {

    #contact h2 {
        font-size: 1.4rem;
    }
}