
#about {
    background: #fa1d4a;
    padding: 20px;
}

#about .about-container {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    align-items: center;
}

#about .about-container .image-container {
    width: 100%;
    margin-right: 50px;
}

#about .about-container .image-container img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border: 2px solid #fff;
}

#about .about-container h1 {
    margin: 0 0 50px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 2rem;
}

#about .about-container .text-container {
    color: #fff;
    font-size: 1.5rem;
}

#about .counter-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: #fff;
}

.counter img {
    height: 75px;
    margin-bottom: 10px;
}

#about .counter-container .counter-number {
    font-weight: 600;
    font-size: 2rem;
    margin: 5px auto;
}

#about .counter-container .counter-text {
    margin-top: 10px;
    font-size: 1.5rem;
}

@media only screen and (max-width: 1000px) {
    #about .about-container {
        flex-direction: column-reverse;
    }

    #about .about-container .image-container {
        margin: 0;
    }
}

@media only screen and (max-width: 800px) {
    #about .counter-container {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #about .counter-container .counter {
        flex-basis: 50%;
        margin: 10px 0;
    }
}

@media only screen and (max-width: 750px) {

    #about {
        background: #fa1d4a;
    }

    #about .about-container {
        padding-top: 10px;
    }
    #about .about-container h1,
    #about .counter-container .counter-number {
        font-size: 1.4rem;
        margin-bottom: 5px;
    }

    #about .about-container .text-container,
    #about .counter-container .counter-text {
        color: #fff;
        font-size: 1.2rem;
    }

    .counter img {
        height: 50px;
        margin-bottom: 10px;
    }

}

@media only screen and (max-width: 500px) {
    #about .counter-container .counter {
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 400px) {

}