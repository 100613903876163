
.image-slider {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
}
.image-slider .slick-dots {
    bottom: 10px;
}
.image-slider img {
    max-width: 100%;
    #width: calc(100%- 300px);
    height: calc(100vh - 100px);
    margin: auto;
    object-fit: cover;
    object-position: center;
}

.image-slider .slick-arrow {
    display: none;
}

.image-slider .slick-dots li button:before {
    color: #fa1d4a;
}

@media only screen and (max-width: 750px) {

    .image-slider img {
        height: 200px;
    }
}