
#footer {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 20px;
}

.footer-text {
    margin: 0; padding: 0;
}