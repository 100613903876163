
.separator-image-container {
    width: 100%;
    background: #000;
    display: flex;
    justify-content: center;
}

.separator-image-container img {
    margin: 0 auto;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .separator-image-container {
        margin-top: -4px;
    }
}

@media only screen and (max-width: 750px) {
    .separator-image-container {
        margin-top: 0;
        height: 35px;
        background: #000;
    }

    .separator-image-container img {
        object-fit: cover;
        display: none;
    }
}