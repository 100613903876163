
#references {
    background: #eee;
    padding: 20px;
}

#references .references-container {

}

#references .references-container h2 {
    margin: 30px auto; padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 2rem;
}

.ref-slider-container {
   /* display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;
    height: 100%;*/
}

.ref-item {
    width: calc(20% - 3px);
    position: relative;
    cursor: pointer;
}

.ref-item-inner {
    padding: 0 2px;
}

.ref-item img {
    width: 100%;
    height: 450px;
    object-fit: cover;
}

.ref-item .ref-item-inner .overlay {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: calc(100% - 3px);
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .7);
    display: none;
    transition: all .3s;
}

.ref-item .ref-item-inner .overlay a {
    text-decoration: none;
}

.ref-item .ref-item-inner .overlay img {
    height: 50px;
    width: 50px;
}

.ref-item .ref-item-inner:hover > .overlay {
    display: block;
    transition: all .3s;
}

.overlay-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 40px);
}

.overlay-name-container {
    display: flex;
    height: 40px;
    width: 100%;
    color: #fff;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}

.overlay-name-container span {
    margin-left: 10px;
    font-weight: 600;
}

.overlay-rating-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-rating-container span {
    margin-right: 10px;
}

.slick-prev, .slick-next {
    top: 48.5%
}

.slick-prev {

}

.slick-next {

}

.slick-prev:before {
    content: '';
}

.slick-next:before {
    content: '';
}

.ref-slider-container .slick-list {
    margin-left: 50px;
    width: calc(100% - 100px);
}

@media only screen and (max-width: 1100px) {
    .ref-item {
        width: calc(25% - 3px);
    }

}

@media only screen and (max-width: 900px) {
    .ref-item {
        width: calc(33% - 3px);
    }
}

@media only screen and (max-width: 750px) {
    #references .references-container h2 {
        font-size: 1.4rem;
    }
}


@media only screen and (max-width: 600px) {
    .ref-item {
        width: calc(50% - 3px);
    }
}

@media only screen and (max-width: 450px) {
    .ref-item {
        width: calc(100% - 3px);
    }
}